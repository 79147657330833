<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    객실시설
                                     <v-btn
                                             color="error"
                                             class="mx-2 white--text center-block float-right mb-0"
                                             x-small
                                             @click="btnClick(2)"
                                     >
                                         추가
                                     </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <table class="table-top-s table-top-s-b">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <col style="width:10%"/>
                                            <col style="width:25%"/>
                                            <col style="width:40%"/>
                                            <col style="width:13%"/>
                                            <col style="width:12%"/>
                                        </colgroup>
                                        <tr>
                                            <!--<th>번호</th>-->
                                            <th>번호</th>
                                            <th>시설이미지</th>
                                            <th>시설명</th>
                                            <th>저장</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in roomItemList"
                                            :key=i
                                        >
                                            <td>
                                                {{item.roomItemId}}
                                            </td>
                                            <td>
                                                <img :src='item.roomItemImg' style="max-width: 40px;">
                                            </td>
                                            <td>
                                                {{item.roomItemName}}
                                            </td>
                                            <td>

                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white--text center-block float-right"
                                                        x-small
                                                        @click="update(item, 2)"
                                                >
                                                    수정
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white--text center-block float-right"
                                                        x-small
                                                        @click="update(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-dialog
                v-model="dialog"
                persistent
                width="500"
        >
            <v-col style="background-color: white">
                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                   객실시설
                </v-col>
            </v-col>
            <v-col style="background-color: white">
                <table class="table-left-s table-left-s-b">
                    <colgroup>
                        <col style="width:30%"/>
                        <col style="width:70%"/>
                    </colgroup>
                    <tr>
                        <th class="th">번호</th>
                        <td class="td">
                            <input v-model="roomItemInfo.roomItemId" placeholder="자동생성" readonly>
                        </td>
                    </tr>
                    <tr>
                        <th class="th">시설명</th>
                        <td class="td">
                            <input v-model="roomItemInfo.roomItemName" placeholder="시설명">
                        </td>
                    </tr>
                    <tr>
                        <th class="th" rowspan="2">이미지</th>
                        <td>
                            <input type="file" name="files" accept="image/*" @change="uploadImage"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <img :src='roomItemInfo.roomItemImg'>
                        </td>
                    </tr>
                </table>
            </v-col>
            <v-col style="background-color: white">
                <v-col cols="12" style="text-align: center; padding-top:0">
                    <v-btn
                            color="primary"
                            class="mx-4 white--text center-block"
                            small
                            @click="update1"
                    >
                        저장
                    </v-btn>
                    <v-btn
                            color="error"
                            class="mx-4 white--text center-block"
                            small
                            @click="dialog = false"
                    >
                        취소
                    </v-btn>
                </v-col>
            </v-col>
        </v-dialog>
    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminRoomItem',
        components: {
            AdminRoomMenu
        },
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,

            roomItemList: [],
            roomItemInfo: {
                /*roomItemId: "",*/
                roomItemImg: "",
                roomItemName: "",
                lstModPrs: "",
            },
            tmpId: 0,
            dialog: false,


        }),
        created: function () {
            // 맨위로
            if ( this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getList({});
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getRoomItemList", val)
                    .then((resp) => {
                        setTimeout(() => {
                            this.roomItemList = resp.message;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            btnClick(val) {
                switch (val) {
                    case 1 : {   //

                        let formdata = {
                            //roomId: "",
                            roomItemName: "",
                            roomItemImg: "",
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            tmpId: this.tmpId,
                        };

                        this.roomItemList.push(formdata);

                        this.tmpId += this.tmpId + 1;

                        break;
                    }
                    case 2 : {

                        this.roomItemInfo = {
                            roomItemName: "",
                            roomItemImg: "",
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            tmpId: this.tmpId,
                        };

                        this.tmpId += this.tmpId + 1;

                        this.dialog = true;


                        break;
                    }
                }

            },
            update(val, isUpdate) {
                switch (isUpdate) {
                    case 0 : {
                        if (!confirm("객실시설을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.roomItemName.length === 0) {
                                alert('항목을 입력해 주세요');

                                return;
                            }


                            return this.$store.dispatch("admin/updateRoomItem", val)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.getList({});

                                    }, 300)
                                })
                                .catch((err) => {
                                });
                        }

                        break;
                    }
                    case 2 : {

                        this.dialog = true;
                        this.roomItemInfo = val;

                        break;
                    }
                    case 1 : {
                        if (!confirm("객실시설을 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.tmpId != undefined) {

                                for (let i = 0; this.roomItemList.length > i; i++) {

                                    if (this.roomItemList[i].tmpId === val.tmpId) {
                                        this.roomItemList.splice(i, 1);
                                    }
                                }
                            } else {
                                return this.$store.dispatch("admin/deleteRoomItem", val)
                                    .then((resp) => {
                                        setTimeout(() => {
                                            this.getList({});

                                        }, 300)
                                    })
                                    .catch((err) => {
                                    })
                            }
                        }


                        break;
                    }

                }

            },
            update1() {


                if (!confirm("객실시설을 저장 하시겠습니까?")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {
                    // 확인(예) 버튼 클릭 시 이벤트
                    if (this.roomItemInfo.roomItemName.length === 0) {
                        alert('항목을 입력해 주세요');

                        return;
                    }

                    this.roomItemInfo.lstModPrs = this.$store.state.admin.loginInfo.usrId;

                    return this.$store.dispatch("admin/updateRoomItem", this.roomItemInfo)
                        .then((resp) => {
                            setTimeout(() => {
                                this.getList({});
                                this.dialog = false;

                            }, 300)
                        })
                        .catch((err) => {
                        });
                }


            },
            uploadImage(e) {
                let url = '/file/upload.do';

                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                let header = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };

                return axios.post(url, formData, header).then(resp => {

                    if (resp.data) {

                        this.roomItemInfo.roomItemImg = resp.data;


                        return resp.data
                    }

                    throw new Error('Server or network error');
                });
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },

    }


</script>

<style>

</style>

