<template>
    <div>
        <div class="sideMenu-title mb-2">
            객실관리
        </div>
        <div class="sideMenu">
            <!--<ul style="width:100%;" class="default-Btn-1 my-12 hidden-sm-and-down">-->
            <div class="sideMenu-group">
                <ul style="width:100%;" class="">
                    <li class="" id="menu-all">
                        <a :class="{active : menuActive.isActive0}" @click="menuClick('/admin/room/list','all')">전체 [{{menuCnt.total}}]</a>
                    </li>
                    <li class="" id="menu-R">
                        <a :class="{active : menuActive.isActive1}" @click="menuClick('/admin/room/list','R')">객실 [{{menuCnt.menu1}}]</a>
                    </li>
                    <li class="" id="menu-A">
                        <a :class="{active : menuActive.isActive2}" @click="menuClick('/admin/room/list','A')">캠핑장A [{{menuCnt.menu2}}]</a>
                    </li>
                    <li class="" id="menu-B">
                        <a :class="{active : menuActive.isActive3}" @click="menuClick('/admin/room/list','B')">캠핑장B [{{menuCnt.menu3}}]</a>
                    </li>
                    <li class="" id="menu-C">
                        <a :class="{active : menuActive.isActive4}" @click="menuClick('/admin/room/list','C')">캠핑장C [{{menuCnt.menu4}}]</a>
                    </li>
                    <li class="" id="menu-D">
                        <a :class="{active : menuActive.isActive5}" @click="menuClick('/admin/room/list','D')">캠핑장D [{{menuCnt.menu5}}]</a>
                    </li>
                    <li class="" id="menu-X">
                        <a :class="{active : menuActive.isActive6}" @click="menuClick('/admin/room/list','X')">미관리 [{{menuCnt.menu6}}]</a>
                    </li>
                </ul>
            </div>
            <v-divider class="mx-5 my-3"></v-divider>
            <div class="sideMenu-group">

                <ul style="width:100%;" class="">
                    <li class="" id="menu-campItem">
                        <a :class="{active : menuActive.isActive7}" @click="menuClick('/admin/campItem/list', null)">추가요금</a>
                    </li>
                    <li class="" id="menu-roomItem">
                        <a :class="{active : menuActive.isActive8}" @click="menuClick('/admin/room/item', null)">객실시설</a>
                    </li>
                </ul>
            </div>
            <v-divider class="mx-5 my-3"></v-divider>
            <div class="sideMenu-group">

                <ul style="width:100%;" class="">
                    <li class="" id="menu-peak">
                        <a :class="{active : menuActive.isActive9}" @click="menuClick('/admin/holi/list', null)">주말/성수기</a>
                    </li>
                    <li class="" id="menu-sale">
                        <a :class="{active : menuActive.isActive10}" @click="menuClick('/admin/sale/list', null)">할인설정</a>
                    </li>
                    <li class="" id="menu-stop">
                        <a :class="{active : menuActive.isActive11}" @click="menuClick('/admin/stop/list', null)">휴업설정</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'AdminRoomMenu',
        data: () => ({
            menuCnt: {
                total: 0,
                menu1: 0,
                menu2: 0,
                menu3: 0,
                menu4: 0,
                menu5: 0,
                menu6: 0,
            },
            menuActive: {
                isActive0: false,
                isActive1: false,
                isActive2: false,
                isActive3: false,
                isActive4: false,
                isActive5: false,
                isActive6: false,
                isActive7: false,
                isActive8: false,
                isActive9: false,
                isActive10: false,
                isActive11: false,
                isActive12: false,
            }
        }),
        created: function () {
            this.getCnt();
            this.setMenuActive();
        },
        computed: {

        },
        methods: {
            getCnt() {
                return this.$store.dispatch("admin/getRoomCnt")
                    .then((resp) => {
                        this.menuCnt = resp.menuCnt;
                    })
                    .catch((err) => {
                    })
            },
            menuClick(route, gubun) {

                if(gubun != null){

                    location.href = route + '?roomGubun=' + gubun;
                }else{
                    location.href = route;
                }
            },
            setMenuActive(){

                switch (window.location.pathname) {
                    case "/admin/campItem/list":{
                        this.menuActive.isActive7 = true;
                        break;
                    }
                    case "/admin/room/item":{
                        this.menuActive.isActive8 = true;
                        break;
                    }
                    case "/admin/holi/list":{
                        this.menuActive.isActive9 = true;
                        break;
                    }
                    case "/admin/sale/list":{
                        this.menuActive.isActive10 = true;
                        break;
                    }
                    case "/admin/stop/list":{
                        this.menuActive.isActive11 = true;
                        break;
                    }
                    case "/admin/room/list":{
                        let roomGubun = this.$getParameterByName('roomGubun');
                        switch (roomGubun) {
                            case "R": {
                                this.menuActive.isActive1 = true;
                                break;
                            }
                            case "A": {
                                this.menuActive.isActive2 = true;
                                break;
                            }
                            case "B": {
                                this.menuActive.isActive3 = true;
                                break;
                            }
                            case "C": {
                                this.menuActive.isActive4 = true;
                                break;
                            }
                            case "D": {
                                this.menuActive.isActive5 = true;
                                break;
                            }
                            case "X": {
                                this.menuActive.isActive6 = true;
                                break;
                            }
                            default :{
                                this.menuActive.isActive0 = true;
                                break;
                            }
                        }
                        break;
                    }
                }

            }

        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
